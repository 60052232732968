<template>
  <base-dialog
    :visible.sync="visible"
    width="854px"
    title="提示"
    :showSubmit="false"
    :showClose="false"
    @close="close"
  >
    <div class="base-info">
      <i class="iconfont icontanhao"></i>
      <span>导入异常！</span>
      <span>请按以下错误提示核对文件信息</span>
    </div>
    <ul class="error-list">
      <li v-for="(item, ids) in errorLists" :key="ids">
        {{ `${item.errorMsg}` }}
      </li>
    </ul>
  </base-dialog>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'

export default {
  components: { baseDialog },
  data () {
    return {
      visible: false,
      errorLists: []
    }
  },
  methods: {
    open (data) {
      this.visible = true
      this.createErrorList(data)
    },
    createErrorList ({ errorRecord = [] }) {
      this.errorLists = errorRecord
    },
    close () {
      this.$nextTick(() => {
        this.errorLists = []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.base-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icontanhao {
    font-weight: normal;
    font-size: 50px;
    color: #2862E7;
  }
  > span {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: #38373a;
  }
}

.error-list {
  background: #f9f9f6;
  box-sizing: border-box;
  padding: 10px;
  max-height: calc(100% - 180px);
  overflow: auto;
  & > li {
    margin-top: 20px;
    font-size: 16px;
    line-height: 18px;
    color: #38373a;
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: #2862E7;
    }
  }
}
</style>
